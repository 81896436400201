import React, {useState, useEffect} from 'react';
import FlexibleFields from '@components/organisms/FlexibleFields';
import Footer from '@components/organisms/footer';
import Layout from '@components/organisms/Layout';
import Navigation from '@components/organisms/navigation';
import SEO from '@containers/SEO';
import breakpoints from '@netlify/site-settings/breakpoints';
import ReactBreakpoints from 'react-breakpoints';

const PageTemplate = ({pageContext: {page, breadcrumb}}) => {
    const {seoMetadata} = page;

    const [ submitSuccess, setSubmitSuccess ] = useState(false);

    useEffect(() => {
        setSubmitSuccess(false);
    }, []);

    return (
        <Layout page={page}>
            <SEO
                pageMeta={seoMetadata}
                pageTitle={page.title}
                breadcrumb={breadcrumb}
            />
            <Navigation />
            <ReactBreakpoints breakpoints={breakpoints}>
                <FlexibleFields
                    fields={page.flexibleFields}
                    formSubmit={submitSuccess}
                    onFormSubmit={setSubmitSuccess}
                />
            </ReactBreakpoints>
            <Footer />
        </Layout>
    );
};

export default PageTemplate;
